import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import WebriQForm from '@webriq/gatsby-webriq-form'

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <p>This is the homepage</p>
          <Link to="/blog">Go to Blog</Link>
          <br />
          <br />
          <h1>Sample Swiper</h1>
          <br />
          <MySwiper slidesperview={3}>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/145698/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2203755/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/362271/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/3657445/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/385548/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/325867/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/1118905/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2270935/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/1252081/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2411320/400x400"
                alt=""
              />
            </div>
          </MySwiper>
          <br />
          <br />
          <br />
          <div className="col-6">
            <h1 className="mt-20">Subscribe!</h1>
            <WebriQForm
              method="POST"
              data-form-id="9af15e69-1223-4445-85b4-38dca65823f2"
              name="Newsletter Form"
              data-thankyou-url="/thank-you"
              scriptSrc="https://pk3v3a4ale.execute-api.us-west-2.amazonaws.com/dev/js/initReactForms"
            >
              <div className="form-row">
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="Email Address"
                      id="email_address"
                      placeholder="info@yourmail.com"
                    />
                    <small className="text-dark form-text mt-2">
                      We will never share your email with anyone else.
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      className="btn btn-primary hvr-shadow"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </WebriQForm>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
